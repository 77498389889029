* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-image: url("./components/images/page-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  margin: 0; /* Add this line to remove default body margin */
}

.custom-container {
  max-width: 1320px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  /* overflow-x: hidden; */
}

@media (max-width: 768px) {
  .custom-container {
    max-width: 720px;
  }
}

@media (max-width: 480px) {
  .custom-container {
    max-width: 90%;
  }
}

.banner {
  background: url("./components/images/backgrounds/banner-bg.jpg");
  border-radius: 25px;
  background-repeat: no-repeat;
}
@media (max-width: 1295px) {
  .banner {
    background-image: url("../src/components/images/backgrounds/banner-bg-2.jpg");
    background-repeat: repeat-y;
  }
}

@media (min-width: 1200px) and (max-width: 1280px) {
  .banner {
    background-image: url("../src/components/images/backgrounds/banner-bg.jpg");
    /* background-repeat: repeat-y; */
  }
}

/* .banner {
  background-repeat: repeat-y;
} */

.secondBg {
  background: url("./components/images/backgrounds/work-bg.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 25px;
}

@media (min-width: 1024px) {
  .lg-3 {
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin: -24px -64px;
  }
}

.container {
  width: 100%;
  padding: 10px;
  max-width: 500px;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
/* 
@media (min-width: 1536px) {
  .container {
      max-width: 1536px;
  }
} */

h2.text-edit {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  padding: 12px 10px;
  width: 100%;
  margin: 25px auto 0px auto;
  border-radius: 10px;
  text-align: center;
  border: 2px solid #e34261;
}

.sell-btn-2 {
  background: linear-gradient(#fff, #fff 50%, #e34261 50%, #e34261);
  background-size: 100% 200%;
  transition: 0.5s;
  color: #000;
  text-align: center;
  border: 2px solid #e34261;
  padding: 10px 20px;
  display: block;
  margin: 20px auto 0;
  font-weight: bold;
}

.sell-btn-2:hover {
  background-position: 100% 100%;
  color: #fff !important;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .secondBg {
    height: auto;
  }

  h2.text-edit {
    font-size: 12px;
    margin: 15px auto 0;
  }

  .sell-btn-2 {
    font-size: 12px;
    padding: 8px 16px;
  }
}

@media screen and (max-width: 480px) {
  h2.text-edit {
    font-size: 12px;
    margin: 10px auto 0;
  }

  .sell-btn-2 {
    font-size: 12px;
    padding: 6px 12px;
  }
}

.thirdBg {
  background: url(../src/components/images/backgrounds/rasoda-banner-3.jpg)
    fixed no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 25px;
}

.contact-form {
  border: 5px solid lightcoral;
  width: 52%;
  margin: 0 auto;
  border-radius: 15px;
  background-color: #fff;
  padding: 30px 20px;
}

@media (max-width: 768px) {
  .contact-form {
    width: 80%;
    padding: 20px 10px;
  }
}

@media (max-width: 480px) {
  .contact-form {
    width: 90%;
    padding: 15px 10px;
  }
}
.input {
  font-size: 0.8rem;
  color: #0e0e0e !important;
  border: 1px solid #e2e2e2;
}

.sell-btn-3:hover {
  background-position: 100% 100%;
  color: #fff !important;
  cursor: pointer;
}

.sell-btn-3 {
  background: linear-gradient(#fff, #fff 50%, #f08080 50%, #f08080);
  background-size: 100% 200%;
  color: #fff;
  background-size: 100% 200%;
  transition: 0.5s;
  color: #000;
  text-align: center;
  border: 2px solid #f08080;
  padding: 10px 30px;
  display: block;
  margin: 20px auto 0;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .sell-btn-3 {
    font-size: 12px;
    padding: 6px 12px;
  }
}

@media screen and (max-width: 480px) {
  .sell-btn-3 {
    font-size: 12px;
    padding: 11px 20px;
  }
}

#socials {
  display: flex;

  justify-content: center;
}

#socials li a {
  width: 40px;

  height: 40px;

  background-color: #fff;

  text-align: center;

  line-height: 40px;

  font-size: 15px;

  margin: 0 10px;

  display: block;

  border-radius: 50%;

  position: relative;

  overflow: hidden;

  border: 3px solid #fff;

  z-index: 1;
}

#socials li a .icon {
  position: relative;

  color: #262626;

  transition: 0.5s;

  z-index: 3;
}

#socials li a:hover .icon {
  color: #fff;

  transform: rotateY(360deg);
}

#socials li a:hover:before {
  top: 0;
}

#socials li:nth-child(1) a:before {
  background: #3b5999;
}

#socials li:nth-child(2) a:before {
  background: #55acee;
}

#socials li:nth-child(3) a:before {
  background: #f76712;
}

#socials li:nth-child(4) a:before {
  background: #dd4b39;
}

#socials li {
  list-style: none;
}

#socials li a:before {
  content: "";

  position: absolute;

  top: 100%;

  left: 0;

  width: 100%;

  height: 100%;

  background: #f00;

  transition: 0.5s;

  z-index: 2;
}
.section {
  padding: 50px;
}

#footer {
  font-size: 14px;

  padding: 65px 0;

  /* float: left; */

  width: 100%;

  border-top: 4px solid #ddd;

  margin-top: 60px;
}

.main-containt,
.main2-containt,
.main3-containt {
  font-family: "Josefin Sans";
  font-weight: 800;
  color: #000;
}

.main-containt {
  position: absolute;
  font-size: 35.5px;
  top: 264px;
  margin-left: 53px;
}

.main2-containt {
  position: absolute;
  font-size: 35.5px;
  top: 264px;
  margin-left: 165px;
}

.main3-containt {
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  top: 347px;
  margin-left: 170px;
}

@media (max-width: 768px) {
  .main-containt,
  .main2-containt,
  .main3-containt {
    font-size: 28px;
  }
  .main-containt {
    margin-left: -128px;
    top: 159px;
  }
  .main2-containt {
    top: 180px;
    margin-left: -40px;
  }
  .main3-containt {
    top: 230px;
    margin-left: -74px;
  }
}

@media (max-width: 480px) {
  .main-containt,
  .main2-containt {
    font-size: 20px;
  }
  .main-containt {
    top: 226px;
    margin-left: -100px;
  }
  .main2-containt {
    top: 240px;
    margin-left: -33px;
  }
  .main3-containt {
    font-size: 18px;
    top: 290px;
    margin-left: -47px;
  }
}

@media (min-width: 1000px) and (max-width: 1024px) {
  .cont {
    margin-top: 5rem;
    margin-left: 40px;
  }
  .imgCls {
    margin-left: 10px;
  }
  .seal {
    margin-top: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .cont {
    margin-left: 10px;
  }
  .imgCls {
    margin-left: -10px;
  }
  .seal {
    margin-top: 10px;
  }
}

@media (min-width: 500px) and (max-width: 767px) {
  .cont {
    margin-top: 3rem;
    margin-left: 105px;
  }
  .imgCls {
    margin-right: 40px;
  }
  .seal {
    margin-top: 10px;
  }
}

@media (min-width: 420px) and (max-width: 499px) {
  .cont {
    margin-top: 2rem;
    margin-left: 50px;
  }
  .imgCls {
    margin-left: 10px;
  }
  .seal {
    margin-top: 10px;
  }
}

@media (min-width: 405px) and (max-width: 419px) {
  .cont {
    margin-left: 10px;
  }
  .imgCls {
    margin-left: 20px;
  }
  .seal {
    margin-top: 10px;
  }
}

@media (min-width: 360px) and (max-width: 404px) {
  .imgCls {
    margin-left: 20px;
  }
  .h1{
    margin-top: 10px;
  }
  .seal {
    margin-top: 10px;
  }
}

@media (min-width: 275px) and (max-width: 359px) {
  .imgCls {
    margin-left: 20px;
  }
  .h1 {
    margin-top: 15px;
  }
  .seal {
    margin-top: 10px;
  }
}


.loader {
  position: relative;
  width: 54px;
  height: 54px;
  border-radius: 10px;
}

.loader div {
  width: 6%;
  height: 18%;
  background: rgb(128, 128, 128);
  position: absolute;
  left: 50%;
  top: 30%;
  opacity: 0;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
  animation: fade458 1s linear infinite;
  /* background-size: 100% 200%;
  color: #fff;
  background-size: 100% 200%;
  transition: 0.5s;
  color: #000;
  text-align: center;
  border: 2px solid #f08080;
  padding: 10px 30px;
  display: block;
  margin: 20px auto 0;
  font-weight: bold; */
}

@keyframes fade458 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.25;
  }
}

.loader .bar1 {
  transform: rotate(0deg) translate(0, -130%);
  animation-delay: 0s;
}

.loader .bar2 {
  transform: rotate(30deg) translate(0, -130%);
  animation-delay: -1.1s;
}

.loader .bar3 {
  transform: rotate(60deg) translate(0, -130%);
  animation-delay: -1s;
}

.loader .bar4 {
  transform: rotate(90deg) translate(0, -130%);
  animation-delay: -0.9s;
}

.loader .bar5 {
  transform: rotate(120deg) translate(0, -130%);
  animation-delay: -0.8s;
}

.loader .bar6 {
  transform: rotate(150deg) translate(0, -130%);
  animation-delay: -0.7s;
}

.loader .bar7 {
  transform: rotate(180deg) translate(0, -130%);
  animation-delay: -0.6s;
}

.loader .bar8 {
  transform: rotate(210deg) translate(0, -130%);
  animation-delay: -0.5s;
}

.loader .bar9 {
  transform: rotate(240deg) translate(0, -130%);
  animation-delay: -0.4s;
}

.loader .bar10 {
  transform: rotate(270deg) translate(0, -130%);
  animation-delay: -0.3s;
}

.loader .bar11 {
  transform: rotate(300deg) translate(0, -130%);
  animation-delay: -0.2s;
}

.loader .bar12 {
  transform: rotate(330deg) translate(0, -130%);
  animation-delay: -0.1s;
}



.spinner-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.spinner-button::before {
  content: '';
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  border-top-color: #3498db;
  border-bottom-color: #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 8px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.notificationCard {
  width: 220px;
  height: 280px;
  background: rgb(245, 245, 245);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 35px;
  gap: 10px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.123);
  border-radius: 20px;
}

.bellIcon {
  width: 50px;
  margin: 20px 0px;
}

.bellIcon path {
  fill: rgb(168, 131, 255);
}

.notificationHeading {
  color: black;
  font-weight: 600;
  font-size: 0.8em;
}

.notificationPara {
  color: rgb(133, 133, 133);
  font-size: 0.6em;
  font-weight: 600;
  text-align: center;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.AllowBtn {
  width: 120px;
  height: 25px;
  background-color: rgb(168, 131, 255);
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 0.7em;
  font-weight: 600;
  cursor: pointer;
}

.NotnowBtn {
  width: 120px;
  height: 25px;
  color: rgb(168, 131, 255);
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-size: 0.7em;
  cursor: pointer;
  border-radius: 20px;
}

.NotnowBtn:hover {
  background-color: rgb(239, 227, 255);
}

.AllowBtn:hover {
  background-color: rgb(153, 110, 255);
}

#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
}

.button {
  width: 215px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 0px 15px;
  background-color: rgb(66, 66, 66);
  border-radius: 10px;
  color: white;
  border: none;
  position: relative;
  /* position: absolute; */
  cursor: pointer;
  transition-duration: .2s;
}

.bell {
  width: 13px;
}

.bell path {
  fill: rgb(0, 206, 62);
}

.arrow {
  position: absolute;
  right: 0;
  width: 30px;
  height: 100%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:hover {
  background-color: rgb(77, 77, 77);
  transition-duration: .3s;
}

.button:hover .arrow {
  animation: slide-right .6s ease-out both;
}
/* arrow animation */
@keyframes slide-right {
  0% {
    transform: translateX(-10px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.button:active {
  transform: translate(1px , 1px);
  transition-duration: .2s;
}

.Invester-main {
  /* min-height: 80vh; */
  /* height: auto; */
  /* width: 100%; */
  /* background: white; */
  /* position: relative; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* border-radius: 25px; */
}
.mySvg {
  /* position: absolute; */
  /* bottom: 0; */
  /* left: 0; */

}
.main-invest {
  /* background: url("./assets/images/main-invest.png"); */
  width: 100%;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 40px;
}

.container1 {
  max-width: 350px;
  background: #F8F9FD;
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(244, 247, 251) 100%);
  border-radius: 40px;
  padding: 25px 35px;
  border: 5px solid rgb(255, 255, 255);
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 30px 30px -20px;
  margin: 20px;
}

.heading {
  text-align: center;
  font-weight: 900;
  font-size: 30px;
  color: rgb(16, 137, 211);
}

.form {
  margin-top: 20px;
}

.form .input {
  width: 100%;
  background: white;
  border: none;
  padding: 15px 20px;
  border-radius: 20px;
  margin-top: 15px;
  box-shadow: #cff0ff 0px 10px 10px -5px;
  border-inline : 2px solid transparent;
}

.form .input::-moz-placeholder {
  color: rgb(170, 170, 170);
}

.form .input::placeholder {
  color: rgb(170, 170, 170);
}

.form .input:focus {
  outline: none;
  /* border-inline: 2px solid #12B1D1; */
}

.form .forgot-password {
  display: block;
  margin-top: 10px;
  margin-left: 10px;
}

.form .forgot-password a {
  font-size: 11px;
  color: #0099ff;
  text-decoration: none;
}

.form .login-button {
  display: block;
  width: 100%;
  font-weight: bold;
  /* background: linear-gradient(45deg, rgb(16, 137, 211) 0%, rgb(18, 177, 209) 100%); */
  background-color: #DC2626;
  color: white;
  padding-block: 15px;
  margin: 20px auto;
  border-radius: 20px;
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 20px 10px -15px;
  border: none;
  transition: all 0.2s ease-in-out;
}

.form .login-button:hover {
  transform: scale(1.03);
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 23px 10px -20px;
}

.form .login-button:active {
  transform: scale(0.95);
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 15px 10px -10px;
}

.social-account-container {
  margin-top: 25px;
}

.social-account-container .title {
  display: block;
  text-align: center;
  font-size: 10px;
  color: rgb(170, 170, 170);
}

.social-account-container .social-accounts {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 5px;
}

.social-account-container .social-accounts .social-button {
  background: linear-gradient(45deg, rgb(0, 0, 0) 0%, rgb(112, 112, 112) 100%);
  border: 5px solid white;
  padding: 5px;
  border-radius: 50%;
  width: 40px;
  aspect-ratio: 1;
  display: grid;
  place-content: center;
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 12px 10px -8px;
  transition: all 0.2s ease-in-out;
}

.social-account-container .social-accounts .social-button .svg {
  fill: white;
  margin: auto;
}

.social-account-container .social-accounts .social-button:hover {
  transform: scale(1.2);
}

.social-account-container .social-accounts .social-button:active {
  transform: scale(0.9);
}

.agreement {
  display: block;
  text-align: center;
  margin-top: 20px;
}

.agreement a {
  text-decoration: none;
  color: #0099ff;
  font-size: 14px;
}
.react-tel-input .country-list {
  /* width: 500px; */
  width: 100%;
  /* display: flex; */
  /* flex-wrap: wrap; */
}

.react-tel-input .country-list .list {
  width: 250px;
}

/* 
.input1 {
  height: 45px;
  width: 260px;
  border-radius: 13px;
  border: none;
}

@media screen and (max-width: 768px) {
  .input1 {
    width: 200px;
    border-radius: 10px;
  }
}

@media screen and (max-width: 480px) {
  .input1 {
    width: 160px;
    border-radius: 8px;
  }
}

@media screen and (max-width: 320px) {
  .input1 {
    width: 120px;
    border-radius: 5px;
  }
} */


.captchain{
  border: 1px solid black;
}
.success-message{
  color: green;
}
.error-message{
  color: red;
}